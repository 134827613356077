/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";

import Header from "./header";
import Footer from "./footer";

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }
  
  constructor(props) {
    super(props);
    this.state = {
      activeElement: null,
    };

    this.headerRef = React.createRef(null);
  }

  componentDidMount() {
    const links = document.querySelectorAll('a[href^="#"], a[href="/"], a[href^="/#"]');
    for (const link of links) {
      link.addEventListener('click', e => this.clickHandler.call(this, e, link));
    }

    window.addEventListener('scroll', this.toggleActiveMenu.bind(this));
    this.toggleActiveMenu();
  }

  toggleActiveMenu() {
    const menuItems = document.querySelectorAll('.masthead-desktop-menu a');
    const headerHeight = parseInt(document.querySelector('#masthead').offsetHeight);
    for (const item of menuItems) {
      const href = item.getAttribute('href');
      let selector = href;
      if (href.length > 1) {
        if (href.substr(0, 2) === '/#') {
          selector = href.slice(1);
        }
      }
      const target = document.querySelector(selector);
      if (target) {
        const scrollY = window.scrollY + headerHeight + (selector === '#nasze-szkolenia' ? 0 : 20);
        if (scrollY >= target.offsetTop && scrollY <= target.offsetTop + target.offsetHeight && this.state.activeElement !== selector) {
          this.setState({ activeElement: selector });
        }
      }
    }
  }

  clickHandler(e, link) {
    const headerHeight = parseInt(document.querySelector('#masthead').offsetHeight);
    const mobileMenu = document.querySelector('#masthead-mobile-menu');
    const trainings = document.querySelector('.our-trainings');
    const href = link.getAttribute('href');
    let selector = href;
    let offsetTop;

    if (window.location.pathname !== `/`) {
      return true;
    }

    if (href.length > 1) {
      if (href.substr(0, 2) === '/#') {
        selector = href.slice(1);
      }
    }

    if (!trainings || !trainings.contains(link)) {
      e.preventDefault();
      if (selector === '/' || selector === '#') {
        offsetTop = 0;
      } else if (selector === '#nasze-szkolenia') {
        offsetTop = parseInt(document.querySelector(selector).offsetTop) - headerHeight;
      } else if (document.querySelector(selector)) {
        offsetTop = parseInt(document.querySelector(selector).offsetTop) - headerHeight - 20;
      } else {
        return true;
      }

      window.scroll({
        top: offsetTop,
        behavior: 'smooth',
      });

      if (mobileMenu.contains(link) && this.headerRef.current) {
        this.headerRef.current.toggleMenu();
      }

      return true;
    }
  }

  render() {
    return (
      <>
        <Header activeElement={this.state.activeElement} ref={this.headerRef} />
        <main id="page-content">
          {this.props.children}
        </main>
        <Footer />
      </>
    );
  }  
}

export default Layout;
