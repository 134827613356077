import * as React from "react";
import { Link } from 'gatsby';

import Logo from './../svg/logo-vss.svg';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpened: false,
      isAnimating: false,
    };

    this.mobileMenuRef = React.createRef(null);
    this.desktopMenuRef = React.createRef(null);
  }

  toggleMenu() {
    if (!this.state.isAnimating && this.mobileMenuRef.current) {
      if (this.state.menuOpened) {
        this.setState({ isAnimating: true, menuOpened: false });
        this.mobileMenuRef.current.style.opacity = '';
        setTimeout(() => { // after the transition ends
          this.mobileMenuRef.current.style.display = '';
          this.setState({ isAnimating: false });
        }, 300);
      } else {
        this.setState({ isAnimating: true, menuOpened: true });
        this.mobileMenuRef.current.style.display = 'block';
        setTimeout(() => { // needs to be done after a delay
          this.mobileMenuRef.current.style.opacity = 1;
        }, 20);
        setTimeout(() => {
          this.setState({ isAnimating: false });
        }, 300);
      }
    }
  }

  componentDidMount() {
    if (window.location.pathname === `/`) {
      if (typeof this.props.activeElement === `string`) {
        this.setActiveElement(this.props.activeElement);
      } else {
        this.setActiveElement('#jak-to-dziala');
      }
    } else {
      this.resetAllMenuElements();
    }
  }

  componentDidUpdate() {
    this.resetAllMenuElements();
    this.setActiveElement(this.props.activeElement);
  }

  resetAllMenuElements() {
    if (this.desktopMenuRef.current) {
      for (const child of this.desktopMenuRef.current.children) {
        child.classList.remove('active');
        child.children[0].blur();
      }
    }
  }

  setActiveElement(id) {
    if (this.desktopMenuRef.current) {
      const item = this.desktopMenuRef.current.querySelector(`a[href*="${id}"]`);
      if (item) {
        item.parentElement.classList.add('active');
      }
    }
  }

  render() {
    return (
      <header id="masthead">
        <nav className="masthead-nav">
          <div className="masthead-left">
            <h1 className="masthead-page-title">
              <Link to="/" className="masthead-logo-link">
                <img 
                  className="masthead-logo" 
                  src={Logo}
                  alt="VSS"
                  width={108}
                  height={50}
                />
              </Link>
            </h1>
            <ul className="masthead-desktop-menu" ref={this.desktopMenuRef}>
              <li><Link to="/#jak-to-dziala">Jak działamy?</Link></li>
              <li><Link to="/#nasze-szkolenia">Szkolenia</Link></li>
              <li><Link to="/#finansowanie">Dotacje</Link></li>
              <li><Link to="/#referencje">Referencje</Link></li>
            </ul>
          </div>
          <div className="masthead-right">
            <div className="masthead-mobile">
              {this.state.menuOpened ? (
                <button type="button" className="masthead-menu-btn" onClick={this.toggleMenu.bind(this)} aria-controls="masthead-mobile-menu">
                  <svg role="presentation" width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.6666 3.83325L3.33325 17.1666M16.6666 17.1666L3.33325 3.83325L16.6666 17.1666Z" stroke="#002C2B" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              ) : (
                <button type="button" className="masthead-menu-btn" onClick={this.toggleMenu.bind(this)} aria-controls="masthead-mobile-menu">
                  <svg role="presentation" width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 3.5H4C3.44772 3.5 3 3.94772 3 4.5V9.5C3 10.0523 3.44772 10.5 4 10.5H9C9.55228 10.5 10 10.0523 10 9.5V4.5C10 3.94772 9.55228 3.5 9 3.5Z" stroke="#002C2B" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9 14.5H4C3.44772 14.5 3 14.9477 3 15.5V20.5C3 21.0523 3.44772 21.5 4 21.5H9C9.55228 21.5 10 21.0523 10 20.5V15.5C10 14.9477 9.55228 14.5 9 14.5Z" stroke="#002C2B" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20 3.5H15C14.4477 3.5 14 3.94772 14 4.5V9.5C14 10.0523 14.4477 10.5 15 10.5H20C20.5523 10.5 21 10.0523 21 9.5V4.5C21 3.94772 20.5523 3.5 20 3.5Z" stroke="#002C2B" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20 14.5H15C14.4477 14.5 14 14.9477 14 15.5V20.5C14 21.0523 14.4477 21.5 15 21.5H20C20.5523 21.5 21 21.0523 21 20.5V15.5C21 14.9477 20.5523 14.5 20 14.5Z" stroke="#002C2B" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span>Menu</span>
                </button>
              )}

              <div id="masthead-mobile-menu" ref={this.mobileMenuRef}>
                <ul className="masthead-mobile-menu-links">
                  <li><Link to="/#jak-to-dziala">Jak działamy?</Link></li>
                  <li><Link to="/#nasze-szkolenia">Szkolenia</Link></li>
                  <li><Link to="/#finansowanie">Dotacje</Link></li>
                  <li><Link to="/#referencje">Referencje</Link></li>
                  <li>
                    <Link to="/#zglos-sie" className="enter-btn">
                      <span>Zgłoś się</span>
                      <svg role="presentation" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 19C17.771 19 19.657 19 20.828 17.828C22 16.657 22 14.771 22 11C22 7.229 22 5.343 20.828 4.172C19.657 3 17.771 3 14 3H10C6.229 3 4.343 3 3.172 4.172C2 5.343 2 7.229 2 11C2 14.771 2 16.657 3.172 17.828C3.825 18.482 4.7 18.771 6 18.898" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 11V11.01" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8 11V11.01" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 11V11.01" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14 19C12.764 19 11.402 19.5 10.159 20.145C8.161 21.182 7.162 21.701 6.67 21.37C6.178 21.04 6.271 20.015 6.458 17.966L6.5 17.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="masthead-desktop">
              <Link to="/#zglos-sie" className="enter-btn">
                <span>Zgłoś się</span>
                <svg role="presentation" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 19C17.771 19 19.657 19 20.828 17.828C22 16.657 22 14.771 22 11C22 7.229 22 5.343 20.828 4.172C19.657 3 17.771 3 14 3H10C6.229 3 4.343 3 3.172 4.172C2 5.343 2 7.229 2 11C2 14.771 2 16.657 3.172 17.828C3.825 18.482 4.7 18.771 6 18.898" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 11V11.01" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 11V11.01" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M16 11V11.01" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M14 19C12.764 19 11.402 19.5 10.159 20.145C8.161 21.182 7.162 21.701 6.67 21.37C6.178 21.04 6.271 20.015 6.458 17.966L6.5 17.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Link>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
