import * as React from "react";
import { Link } from 'gatsby';

import Logo from "./../svg/logo-vss.svg";
import LogoNettsteder from "./../svg/logo-nettsteder.svg";

const Footer = () => (
  <footer id="site-footer">
    <div className="container">
      <div className="footer-wrapper">
        <div className="footer-left">
          <Link className="footer-logo-link" to="/">
            <img className="footer-logo" src={Logo} alt="VSS" width={112} height={52} />
          </Link>
          <ul className="footer-menu">
            <li><Link to="/#jak-to-dziala">Jak to działa?</Link></li>
            <li><Link to="/#nasze-szkolenia">Nasze szkolenia</Link></li>
            <li><Link to="/#wspolpraca">Współpraca</Link></li>
            <li><Link to="/#oferta-bur">Oferta BUR</Link></li>
            <li><Link to="/#finansowanie">Finansowanie</Link></li>
            <li><Link to="/#referencje">Referencje</Link></li>
            <li><Link to="/#zglos-sie">Kontakt</Link></li>
            <li><Link to="/procedura-reklamacji">Procedura reklamacji</Link></li>
          </ul>
        </div>
        <div className="footer-right">
          <figure className="footer-quote">
            <blockquote>
              <p>Jedyne co trzeba zrobić, to zająć miejsce w kolejce sukcesu i nie dać się z niej wypchnąć.</p>
            </blockquote>
            <figcaption>&#126; Brian Tracy</figcaption>
          </figure>
        </div>
      </div>
      <div className="footer-copyright">
        Powered by
        <a href="https://nettsteder.pl" target="_blank" rel="noreferrer">
          <img src={LogoNettsteder} alt="Nettsteder" width={109} height={23} />
        </a>
      </div>
      <div className="footer-go-up">
        <a className="footer-go-up-link" href="#">
          <svg role="presentation" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 26.6668V5.3335" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.66669 14.6668L16 5.3335L25.3334 14.6668" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </a>
        <div className="footer-go-up-text">Do góry!</div>
      </div>
    </div>
  </footer>
);

export default Footer;
